import React, { useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import s from './account-selector.component.module.css';
import { useBanksContext } from '../../../context/banks-context';

export interface BankRepr {
  id: string;
  value: string;
}

interface Props {
  setBank: any;
  selectedBankName?: string;
  isPage?: boolean;
}

export const AccountSelector: React.FC<Props> = ({ setBank, selectedBankName, isPage }) => {
  const { banks } = useBanksContext();
  const [selectedValues, setSelectedValues] = useState<BankRepr[]>([]);
  const [selectedBank, setSelectedBank] = useState<string | undefined>(selectedBankName);

  useEffect(() => {
    const selected = selectedValues[0];
    const { value } = selected || {};
    if (selected && setBank) {
      setSelectedBank(value);
      setBank(selected);
    }
  }, [selectedValues, setBank]);

  return (
    <>
      <div className={s.root}>
        {!isPage && (
          <Select
            placeholder="Select Bank"
            wrapperClassName={s.select}
            options={banks
              .map((b) => {
                return { id: b.id, value: b.bank };
              })
              .filter((b) => b.value !== 'Central Bank')}
            onChange={(banks) => setSelectedValues(banks)}
            values={selectedValues}
            valueField="id"
            labelField="value"
            style={{ width: '300px' }}
          ></Select>
        )}
        <p className={s.selected}>
          Account ID: <b>{selectedBank || process.env.REACT_APP_BANK_NAME}</b>
        </p>
      </div>
    </>
  );
};
