import React, { useState, useEffect } from 'react';

interface NumberChangeEffectProps {
  number: number;
  duration: number;
}

const NumberChangeEffect: React.FC<NumberChangeEffectProps> = ({ number, duration }) => {
  const [displayNumber, setDisplayNumber] = useState(0);

  useEffect(() => {
    let usedNumber = number;
    if (typeof number !== 'number') {
      usedNumber = 0;
    }
    let startTimestamp: number;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      setDisplayNumber(Math.floor(progress * usedNumber));
      if (progress < 1) window.requestAnimationFrame(step);
    };
    window.requestAnimationFrame(step);
  }, [number, duration]);

  // FIXME: since we did not make a final decision on the number
  // of decimals we use, use static .0 ending and UTD as the currency
  // name.
  return <span>{displayNumber.toLocaleString()}.0 UTD</span>;
};

export default NumberChangeEffect;
