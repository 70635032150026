import s from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { useSocketContext } from '../../../context/socket-context';
import { BankInfo, BankResponse } from '../../../components/interfaces';
import { getBanks } from '../../../utils/http';
import { useAuthContext } from '../../../context/auth-context';
import { fetchAccountId } from '../../../utils/functions';
import { getBankBalance, getAllBankBalances } from '../../../utils/http';
import NumberChangeEffect from '../../../components/common/number-effect';
import { useBanksContext } from '../../../context/banks-context';

interface Props {
  id: string;
}

export const BalanceDetails: React.FC<Props> = ({ id }) => {
  const { isAuthorized } = useAuthContext();
  const { banks, setBanks } = useBanksContext();

  const {
    SocketState: { lastMessage },
  } = useSocketContext();
  const [selectedBank, setSelectedBank] = useState<BankInfo | undefined>(undefined);

  const [availableWholesale, setAvailableWholesale] = useState(0);
  const [totalWholesale, setTotalWholesale] = useState(0);
  const [availableReserve, setAvailableReserve] = useState(0);
  const [totalReserve, setTotalReserve] = useState(0);

  useEffect(() => {
    if (!isAuthorized) {
      setBanks([]);
      setSelectedBank(undefined);
      setAvailableReserve(0);
      setTotalReserve(0);
      setAvailableWholesale(0);
      setTotalWholesale(0);
      return;
    }
    let active = true;

    const fetchData = async () => {
      if (!active) return;

      const getAddresses = async (bank: BankResponse) => {
        const digitalFundsAddress = await fetchAccountId(bank.id, 'Main', 'main');
        return {
          id: bank.id,
          bank: bank.name,
          reserve: bank.reserveAddress,
          digital: digitalFundsAddress!,
          bic: bank.bic,
          iban: bank.iban,
          accountId: bank.accountId,
        };
      };

      const availableBanks = (await getBanks()) as BankResponse[];
      const allBankDataPromises = availableBanks.map(getAddresses);
      const allBankData = await Promise.all(allBankDataPromises);
      console.log('allBankData', allBankData);
      setBanks((prev) => [...prev, ...allBankData]);
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [isAuthorized]);

  useEffect(() => {
    setSelectedBank(banks.find((b) => b.id === id));
  }, [banks, id]);

  useEffect(() => {
    if (!selectedBank) {
      return;
    }
    (async () => {
      const { reserve, digital } = selectedBank;

      const [{ totalUsable: aWholesale, grandTotal: tWholesale }, { usable: aReserve, total: tReserve }] =
        await Promise.all([getAllBankBalances(digital), getBankBalance(reserve, 1)]);
      setAvailableWholesale(aWholesale);
      setTotalWholesale(tWholesale);
      setAvailableReserve(aReserve);
      setTotalReserve(tReserve);
    })();
  }, [lastMessage, selectedBank]);

  return (
    <div className={s.root}>
      <div className="bo">
        <div className={s.balances}>
          <div className="account-card">
            <h4>Available Reserve</h4>
            <NumberChangeEffect number={availableReserve} duration={500} />
          </div>
          <div className="account-card">
            <h4>Total Reserve</h4>
            <NumberChangeEffect number={totalReserve} duration={500} />
          </div>
          <div className="account-card">
            <h4>
              Available Digital<small>&nbsp;(wCBDC, rCBDC)</small>
            </h4>
            <NumberChangeEffect number={availableWholesale} duration={500} />
          </div>
          <div className="account-card">
            <h4>
              Total Digital<small>&nbsp;(wCBDC, rCBDC)</small>
            </h4>
            <NumberChangeEffect number={totalWholesale} duration={500} />
          </div>
        </div>
      </div>
    </div>
  );
};
