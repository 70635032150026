import React, { useState, useEffect } from 'react';
import { useBanksContext } from '../../context/banks-context';
import { useSocketContext } from '../../context/socket-context';
import { BankInfo } from '../interfaces';
import s from './index.module.scss';
import ObjectView from './json-view';

export interface Message {
  id: number;
  content: any;
  isExpanded: boolean;
}

interface MessageListProps {
  initialMessages?: Message[];
}

const MessageList: React.FC<MessageListProps> = ({ initialMessages = [] }) => {
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const { SocketState } = useSocketContext();
  const [count, setCount] = useState<number>(0);
  const { banks } = useBanksContext();

  useEffect(() => {
    if (!SocketState?.lastMessage) return;
    setCount((v) => v + 1);
    const message: Message = {
      content: SocketState.lastMessage,
      isExpanded: false,
      id: count,
    };
    console.log('baaanks', banks);
    setMessages((prevMessages) => [message, ...prevMessages.slice(0, 49)]);
  }, [SocketState, banks]);

  const toggleExpand = (id: number) => {
    setMessages((prevMessages) =>
      prevMessages.map((message) => (message.id === id ? { ...message, isExpanded: !message.isExpanded } : message)),
    );
  };

  const getBankById: (id: string | undefined) => BankInfo | undefined = (id) => {
    return id === undefined ? undefined : banks.find((b) => b.id === id);
  };

  const getCommonTxBanks: (
    transactionType: string,
    data: Record<string, any>,
  ) => { senderBic?: string; receiverBic?: string } = (transactionType, data) => {
    switch (transactionType) {
      case 'transfer': {
        const senderId = data?.fromWallet?.bank;
        const receiverId = data?.toWallet?.bank;
        return {
          senderBic: getBankById(senderId ? String(senderId) : undefined)?.bic,
          receiverBic: getBankById(receiverId ? String(receiverId) : undefined)?.bic,
        };
      }
      case 'mint':
      case 'burn':
        return {
          senderBic: getBankById('00000000000000000000000000')?.bic, // CB
        };
      // case 'create_conversion': {
      //   const senderId = data?.fromWallet?.bank;
      //   const receiverId = data?.toWallet?.bank;
      //   return {
      //     senderBic: getBankById(senderId ? String(senderId) : undefined),
      //     receiverBic: getBankById(receiverId ? String(receiverId) : undefined),
      //   };
      // }
      default:
        return {};
    }
  };

  return (
    <div className={s.root}>
      <div className={s.header}>
        <span>TxClass</span>
        <span>MsgType</span>
        <span>Amount</span>
        <span>BlockchainTxId</span>
        <span>Currency</span>
        <span>SenderBic</span>
        <span>ReceiverBic</span>
        <span>Direction</span>
      </div>
      <div className={s.table}>
        {messages &&
          messages.map((message) => {
            const transactionType = message.content?.transactionType;
            const transactionArgs = message.content?.transactionArgs[message.content?.transactionType];
            const { senderBic, receiverBic } = getCommonTxBanks(transactionType, transactionArgs);

            return (
              <div className={s.table__item} key={message.id} onClick={() => toggleExpand(message.id)}>
                <div className={s.info}>
                  <span className={s.info__tx_type}>{transactionType.toUpperCase()}</span>
                  <span>Pacs.009</span>
                  <span>{transactionArgs?.amount}</span>
                  <span className={s.info__tx_id}>{message.content?.transactionId}</span>
                  <span>UTD</span>
                  <span>{senderBic}</span>
                  <span>{receiverBic}</span>
                  <span>
                    {['fulfill_conversion', 'cancel_conversion'].includes(transactionType) ? 'Outgoing' : 'Incoming'}
                  </span>
                </div>
                {message.isExpanded && (
                  <div style={{ width: '100%', overflow: 'hidden', marginTop: '10px' }}>
                    <ObjectView object={message.content} />
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MessageList;
