import React from 'react';
import { AuthLayout } from '../../components/layouts/auth-layout';
import s from './index.module.scss';
import { BankList } from './bank-list';

export const Banks: React.FC = () => {
  return (
    <AuthLayout>
      <div className={s.top}>
        <BankList />
      </div>
    </AuthLayout>
  );
};
