import { useAuthContext } from '../../../context/auth-context';
import React, { useEffect } from 'react';
import { BankResponse } from '../../../components/interfaces';
import { getBanks } from '../../../utils/http';
import { fetchAccountId } from '../../../utils/functions';
import { BankItem } from '../bank-item';
import s from './index.module.scss';
import { useBanksContext } from '../../../context/banks-context';

export const BankList: React.FC = () => {
  const { isAuthorized } = useAuthContext();

  const { banks, setBanks } = useBanksContext();

  useEffect(() => {
    if (!isAuthorized) {
      setBanks([]);
      return;
    }
    let active = true;

    const fetchData = async () => {
      if (!active) return;

      const getAddresses = async (bank: BankResponse) => {
        const digitalFundsAddress = await fetchAccountId(bank.id, 'Main', 'main');
        return {
          id: bank.id,
          bank: bank.name,
          reserve: bank.reserveAddress,
          digital: digitalFundsAddress!,
          bic: bank.bic,
          iban: bank.iban,
          accountId: bank.accountId,
        };
      };

      const availableBanks = (await getBanks()) as BankResponse[];
      const allBankDataPromises = availableBanks.map(getAddresses);
      const allBankData = await Promise.all(allBankDataPromises);
      console.log('allBankData', allBankData);
      setBanks((prev) => [...prev, ...allBankData]);
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [isAuthorized, setBanks]);

  useEffect(() => {
    console.log('Bank Addresses Data', banks);
  }, [banks]);

  return (
    <div className={s.root}>
      <div className={s.content}>
        {banks.map((bank) => bank.bank !== 'Central Bank' && <BankItem key={bank.id} {...bank} />)}
      </div>
    </div>
  );
};
