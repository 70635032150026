import React, { ReactNode } from 'react';
import { SocketContextComponent } from '../../../context/socket-component';
import s from './index.module.scss';
import useLogin from '../../hooks/use-login';
import { Sidebar } from './sidebar';
import { Navbar } from './nav';
import MessageList /* , { Message } */ from '../../common';
import { BanksProvider } from '../../../context/banks-context';

interface Props {
  children?: ReactNode;
}

// const testString =
//   {"status":"NEW","transactionId":"73ba4512-36ea-49b2-b787-40b8ae68269d","caller":"5GrwvaEF5zXb26Fz9rcQpDWS57CtERHpNehXCPcNoHGKutQY","callerEmail":"admin@inx.co","callerBank":"Central Bank","transactionType":"mint","transactionArgs":{"bank":"VladBank","walletName":"Main","addressIndex":0,"assetId":0,"amount":"234"},"events":[]};

export const AuthLayout: React.FC<Props> = ({ children }) => {
  // const _fake: Message[] = [
  //   { isExpanded: false, content: testString, id: 0 },
  //   {
  //     isExpanded: true,
  //     content: testString,
  //     id: 1,
  //   },
  //   { isExpanded: true, content: testString, id: 2 },
  //   { isExpanded: true, content: testString, id: 3 },
  //   {
  //     isExpanded: true,
  //     content: testString,
  //     id: 4,
  //   },
  //   { isExpanded: true, content: testString, id: 5 },
  //   { isExpanded: true, content: testString, id: 6 },
  // ];
  const { isAuthorized } = useLogin();
  // const {token} = {token: "sdf"};

  return (
    <div className={s.root}>
      <Sidebar />
      <div className={s.content}>
        <div className={s.nav}>
          <Navbar />
        </div>
        <div className={s.c}>
          {isAuthorized ? (
            <BanksProvider>
              <SocketContextComponent isAuthorized={isAuthorized}>
                {children}
                <div className={s.bottom}>
                  {
                    // <MessageList initialMessages={_fake} />
                  }
                  <MessageList initialMessages={[]} />
                </div>
              </SocketContextComponent>
            </BanksProvider>
          ) : (
            <div
              style={{
                textAlign: 'center',
                marginTop: '300px',
                fontWeight: 'bold',
              }}
            >
              LOADING...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
