import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { BankInfo } from '../components/interfaces';

interface BanksContextState {
  banks: BankInfo[],
  setBanks: React.Dispatch<React.SetStateAction<BankInfo[]>>;
}

const defaultState: BanksContextState = {
  banks: [],
  setBanks: () => {},
};
const BanksContext = createContext<BanksContextState>(defaultState);

export const BanksProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [banks, setBanks] = useState<BankInfo[]>([]);

  return <BanksContext.Provider value={{ banks, setBanks }}>{children}</BanksContext.Provider>;
};

export const useBanksContext = () => useContext(BanksContext);
