import React, { useEffect, useState } from 'react';
import { AuthLayout } from '../../components/layouts/auth-layout';

import s from '../banks/index.module.scss';
import MessageList from '../../components/common';
import { useParams } from 'react-router-dom';
import { AccountSelector } from '../../components/common/selector/account-selector.component';
import { BalanceDetails } from './balances';

export const BankDetails: React.FC = () => {
  const { id, name } = useParams();

  const [currentId, setCurrentId] = useState<string | undefined>(undefined);
  const [currentName, setCurrentName] = useState<string | undefined>(undefined);
  const [selectedBank, setSelectedBank] = useState<{
    id: string;
    value: string;
  }>();

  useEffect(() => {
    if (!id || !name) return;
    setCurrentName(name);
    setCurrentId(id);
  }, [id, name]);

  useEffect(() => {
    if (!selectedBank) return;

    setCurrentName(selectedBank?.value);
    setCurrentId(selectedBank?.id);
  }, [selectedBank]);
  return (
    <AuthLayout>
      <div className={s.top}>
        <div className={s.balances}>
          <AccountSelector setBank={setSelectedBank} selectedBankName={currentName} isPage={!!id} />
          <BalanceDetails id={currentId || ''} />
        </div>
      </div>
    </AuthLayout>
  );
};
